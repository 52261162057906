<template>
    <li class="mb-1">
        <a v-bind="$attrs"
            :href="(baseUrl + to)"
            :class="'btn align-items-center rounded'"
        >
            <slot />
        </a>
    </li>
</template>

<script>
// import { RouterLink } from 'vue-router'

export default {
    name: 'BsMenuItem',
    inheritAttrs: false,
    data: function() {
        return {
            baseUrl: document.location.origin + process.env.VUE_APP_BASE_URL // process.env.VUE_APP_DOMAIN + process.env.VUE_APP_BASE_URL
        };
    },
    // data: function() {
    //     return {
    //         activeClass: 'menu-active',
    //         inactiveClass: 'menu-not-active'
    //     };
    // },
    props: {
        to: String
    }
}
</script>

<style>
</style>