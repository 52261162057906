<template>
    <div v-if="onRequest" class="interceptor">
        <div class="interceptor-info">
            <div>
                <div class="spinner-border" style="width: 6rem; height: 6rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div>
                Processing...
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'RequestInterceptor',
    computed: {
        onRequest() {
            return this.$store.state.progressCounter > 0;
        }
    }
}
</script>

<style scoped>
    .interceptor {
        z-index: 3000 !important;
        width: 100%;
        height: 100vh;
        position: fixed;
        background: #4848489e;
        top: 0px;
        left: 0px;
    }
    .interceptor-info {
        color: white;
        font-size: 50px;
        width: 100vw;
        text-align: center;
        margin-top: calc((100vh/1/3));
    }
</style>