<template>
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" :aria-labelledby="backdropId" aria-hidden="true">
        <div :class="'modal-dialog ' + popupSize">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="backdropId">
                        <slot name="title"></slot>
                    </h5>
                    <button type="button" class="btn-close" @click="closePopup" aria-label="Close"></button>
                </div>
                
                <div class="progress" style="height:3px;border-radius:0px;" v-if="onLoad">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div class="modal-body">    
                    <slot name="content"></slot>
                </div>

                <div class="modal-footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as bootstrap from "bootstrap";

export default {
    name: "BsPopup",
    data: function() {
        const id = 'm-' + ([1e7]+1e3).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
        return {
            backdropId: 'backdrop-' + id,
            el: null,
            modal: null
        };
    },
    methods: {
        showPopup() {
            this.modal.show();
        },
        closePopup() {
            this.modal.hide();
            this.$emit('onPopupClosed');
        }
    },
    computed: {
        onLoad: function() {
            return this.$store.state.requestCounter;
        },
        popupSize: function() {
            if (this.isLarge) return 'modal-custom-size'; // 'modal-xl';
            if (this.isMedium) return 'modal-medium-size';
            return '';
        }
    },
    props: {
        autoShow: Boolean,
        isLarge: Boolean,
        isMedium: Boolean
    },
    mounted() {
        let modal = new bootstrap.Modal(this.$el);
        if (this.autoShow) {
            modal.show();
        }
        
        this.el = this.$el;
        this.modal = modal;
    }
}
</script>

<style>
    .modal-custom-size {
        min-width:90vw !important;
    }
    .modal-custom-size > .modal-content > .modal-body {
        min-height: 80vh !important;
    }
    .modal-medium-size {
        min-width:30vw !important;
    }
    .modal-medium-size > .modal-content > .modal-body {
        min-height: 80vh !important;
    }
</style>