import moment from "moment";

export default ({
    stringToDate(stringValue)
    {
        if (stringValue === undefined || stringValue === null) return null;
        return moment(stringValue).toDate();
    },
    dateToDateFormat(dateValue) 
    {
        if (dateValue === undefined || dateValue === null) return '';
        return moment(dateValue).format("yyyy.MM.DD");
    },
    dateToDateTimeFormat(dateValue) {
        if (dateValue === undefined || dateValue === null) return '';
        return moment(dateValue).format("yyyy.MM.DD HH:mm:ss");
    }
})