<template>
    <bs-select v-model="text" @onSelect="selectYear" @onSearch="filterYear" :options="list">
        <template v-slot:default="props">
            <div>{{props.item.label}}</div>
        </template>
    </bs-select>
</template>
<script>
export default {
    name: 'YearCombo',
    data: function() {
        const yearData = new Array();
        const currentYear = new Date().getFullYear();
        for (let year = currentYear; year >= currentYear - 5; year--) {
            yearData.push({
                id: year,
                label: year
            });
        }
        return {
            text: '',
            list: null,
            hasFocus: false,
            combo: yearData
        }
    },
    props: {
        modelValue: Number,
        textDefault: String
    },
    updated() {
        // if (this.textDefault != null && this.textDefault != "" && !this.hasFocus) 
        // {
        //     this.text = this.textDefault;
        //     this.hasFocus = true;
        // }
    },
    mounted() {
        this.setComboLabel(this.modelValue);
    },
    emits: ['update:modelValue'],
    methods: {
        async setComboLabel(id) {
            if (id === undefined || id === null || id === '' || id < 1) 
            {
                return;
            }

            let result = await this.combo.filter(f => f.id.toString() == id);
            this.text = result[0].label;
        },
        selectYear(item) {
            if (item == null) {
                this.text = '';
                this.$emit('update:modelValue', null);
            }
            else 
            {
                this.text = item.label;
                this.$emit('update:modelValue', item.id);
            }
            this.setComboLabel(item == null ? null : item.id);
        },
        async filterYear(value) {
            const result = await this.combo.filter(f => value === undefined || value === null || value === '' || f.id.toString().includes(value));
            this.list = result;
        }
    }
}
</script>