<template>
  <bs-select v-model="text" @onSelect="selectBasePrice" @onSearch="filterBasePrice" :options="list">
    <template v-slot:default="props">
      <div>{{props.item.profilNama}} - {{props.item.currencyNama}} {{props.item.quantity}}</div>
    </template>
  </bs-select>
</template>
<script>
import BasePriceService from "@/services/base-price-service";

export default {
  name: 'BasePriceCombo',
  data: function() {
    return {
      text: '',
      list: null,
      hasFocus: false
    }
  },
  props: {
    modelValue: Number,
    textDefault: String
  },
  updated() {
    if (!this.hasFocus)
    {
      this.hasFocus = true;
      this.setComboLabel(this.modelValue);
    }
  },
  emits: ['update:modelValue'],
  methods: {
    async setComboLabel(id) {
      if (id === undefined || id === null || id === '' || id < 1) {
        this.text = '';
        return;
      }

      const obj = await BasePriceService.getObject(id);
      if (obj.status) {
        this.text = `${obj.data.profilNama} - ${obj.data.currencyNama} ${obj.data.quantity}`;
      }
      else {
        this.text = '';
        alert(`Failed load unit with id = '${id}'.`);
      }
    },
    selectBasePrice(item) {
      if (item == null) {
        // this.text = '';
        this.$emit('update:modelValue', null);
      }
      else
      {
        // this.text = item.code;
        this.$emit('update:modelValue', item.id);
        this.$emit('returnItemSelected', item);

      }
      this.setComboLabel(item == null ? null : item.id);
    },
    async filterBasePrice(value) {
      const filter = {
        codeContains: value
      };

      const result = await BasePriceService.getList(filter);
      let arrayResult = null;
      console.log(result)
      if (result.status) {
        if (result.data != null && result.data.data != null)
        {
          arrayResult = result.data.data;
        }
      }

      this.list = arrayResult;
    }
  }
}
</script>