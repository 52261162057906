<template>
    <input 
        v-if="fileName === null || fileName === undefined || fileName === ''"
        type="file" 
        @input="onChangeFile($event)" 
        class="form-control form-control-sm"
        ref="file"
         />
    <div v-if="fileName !== null && fileName !== undefined && fileName !== ''" class="input-group flex-nowrap">
        <input :value="fileName" class="form-control form-control-sm" />
        <span class="input-group-text" id="basic-addon2" @click="clearFile">
            <i class="bi bi-x-lg" style="color:red"></i>
        </span>
    </div>

    <div class="progress mt-2" v-if="onProgress">
        <div class="progress-bar" role="progressbar" :style="'width: ' + percentage + '%'" :aria-valuenow="percentage" aria-valuemin="0" aria-valuemax="100">
            {{Math.ceil(percentage)}} %
        </div>
    </div>
</template>

<script>
import SecurityService from '@/services/security-service';

export default {
    name: "BsFile",
    data: function() {
        let _authServer = new SecurityService();
        return {
            onProgress: false,
            percentage: 0,
            authServer: _authServer
        };
    },
    props: {
        fileName: String
    },
    emits: ['success', 'error', 'clear'],
    methods: {
        clearFile() {
            this.$emit('clear');
        },
        async onChangeFile() {
            let $this = this;
            const uploadPath = process.env.VUE_APP_UPLOAD_FILE;
            
            let formData = new FormData();
            formData.append("files", this.$refs.file.files[0]);

            let xhr = new XMLHttpRequest();
            xhr.open("POST", uploadPath, true);
            let token = '';
            if (this.authServer != null) 
            {
                token = await this.authServer.getAccessToken();
            }
            xhr.setRequestHeader('authorization', `Bearer ${token}`)
            xhr.onload = function() {
                try {
                    $this.onProgress = false;
                    if (xhr.status === 200) {
                        const result = JSON.parse(xhr.response);
                        $this.$emit("success", result.data);
                    }
                    else if (xhr.status === 400) 
                    {
                        const result = JSON.parse(xhr.response);
                        $this.$refs.file.value = "";
                        $this.$emit("error", result.errorMessages);
                        alert("Upload Failed.");
                    }
                    else 
                    {
                        $this.$refs.file.value = "";
                        $this.$emit("error", { "_ERRORS_": ["Failed Upload File"] });
                        alert("Failed Upload File");
                    }
                } 
                catch (e) 
                {
                    $this.$emit("error", { "_ERRORS_": ["Failed Upload File"] });
                    alert("Failed Upload File");
                }
            };
            xhr.upload.onprogress = function(e) {
                $this.onProgress = true;
                $this.percentage = (e.loaded / e.total) * 100;
            };
            xhr.send(formData);
        }
    }
}
</script>

<style>

</style>