<template>
    <input 
        type="checkbox" 
        :value="modelValue" 
        @change="onChangeCheckbox($event)"
         />
</template>

<script>
export default {
    name: "BsCheckbox",
    props: {
        modelValue: Boolean
    },
    methods: {
        onChangeCheckbox(event) {
            this.$emit('update:modelValue', event.target.checked);
        }
    },
    mounted: function() {
        this.$el.checked = false;
        if (this.$el.value == true || this.$el.value.toLocaleLowerCase() == "true") 
        {
            this.$el.checked = true;
        }
    }
}
</script>

<style>

</style>