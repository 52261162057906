<template>
    <div class="accordion mb-3" :id="formId">
        <div class="accordion-item">
            <h2 class="accordion-header" :id="formHeaderId">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="formBodyTargetId" aria-expanded="true" :aria-controls="formHeaderId">
                    <strong>
                        <slot name="title" />
                    </strong>
                </button>
            </h2>
            <div :id="formBodyId" class="accordion-collapse collapse">
                <div class="accordion-body">
                    <slot name="content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BsSearchForm",
    data: function () {
        const id = 'm-' + ([1e7]+1e3).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
        return {
            formId: 'bsSearchForm' + id,
            formHeaderId: 'bsSearchFormHeader' + id,
            formBodyId: 'bsSearchFormBody' + id,
            formBodyTargetId: '#bsSearchFormBody' + id
        };
    }
}
</script>
