<template>
  <bs-select v-model="text" @onSelect="selectStartDay" @onSearch="filterStartDay" :options="list">
    <template v-slot:default="props">
      <div>{{props.item.label}}</div>
    </template>
  </bs-select>
</template>
<script>
import {OPSI_START_DAY} from "@/plugins/constant";

export default {
  name: 'StartDayCombo',
  data: function() {
    return {
      text: '',
      list: null,
      hasFocus: false
    }
  },
  props: {
    modelValue: Number,
    textDefault: String
  },
  updated() {
    if (!this.hasFocus)
    {
      this.hasFocus = true;
      this.setComboLabel(this.modelValue);
    }
  },
  emits: ['update:modelValue'],
  methods: {
    setComboLabel(id) {
      if (id === undefined || id === null || id === '' || id < 1) {
        this.text = '';
        return;
      }else {
        this.text = OPSI_START_DAY.filter((item)=>item.value === id)[0].label;
      }
    },
    selectStartDay(item) {
      if (item == null) {
        this.$emit('update:modelValue', null);
      }
      else
      {
        this.$emit('update:modelValue', item.value);
      }
      this.setComboLabel(item == null ? null : item.value);
    },
    filterStartDay(value) {
      if (value){
        this.list = OPSI_START_DAY.filter(obj => {
          return obj.label.toLowerCase().includes(value.toLowerCase());
        });
      }else{
        this.list = OPSI_START_DAY;
      }

    }
  }
}
</script>