import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`currencies`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`currencies/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async create(model) {
        const response = await httpService.post('currencies', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        const response = await httpService.update('currencies', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('currencies', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async setAsDefault(model, id) {
        const response = await httpService.update('currencies/SetDefault', id, model);
        if (!response.status) {
            response.data = false;
        }

        return response;
    },

    default() {
        return {
            id: 0,
            code: null,
            description: null
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.code = jsonData.code;
        result.description = jsonData.description;
        result.isDefault = jsonData.isDefault;

        return result;
    }
});