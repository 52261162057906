<template>
    <bs-popup id="invoice-receipt-popup" @on-popup-closed="onPopupClosed" :auto-show="false" :isLarge="true" ref="form">
        <template v-slot:title>Invoice Receipt</template>
        <template v-slot:content>
            <div class="row justify-content-between">
                <div class="col-md-6">
                    Data Per Page
                    <select id="select-total-page" @change="onChangeSelectPerPage()" v-model="selectPerPage" name="total-page">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">100</option>
                        <option value="100000">All</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <div class="pagination-right">
                        <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
                    </div>
                </div>
            </div>
            <div>Total Data : {{ this.totalRow }}</div>
            <div class="table-responsive" style="overflow:scroll; height: 66vh;">
                <table class="table table-stripped">
                    <thead>
                        <tr>
                            <th>Tick</th>
                            <th>Vendor Invoice</th>
                            <th>Receipt Number</th>
                            <th>Invoice Date</th>
                            <th>Submit Date</th>
                            <th>Delivery Date</th>
                            <th>Qty</th>
                            <th>Unit</th>
                            <th>Total Price</th>
                            <th>Curr</th>
                            <th>SO</th>
                            <th>PR</th>
                        </tr>
                        <tr>
                            <th>
                                <bs-checkbox v-model="checkAll" @change="onChangeCheckAll" />
                            </th>
                            <th>
                                <bs-textbox placeholder="Vendor Invoice" v-model="filter.VendorInvoiceNoContains" @keyup="search()" />
                            </th>
                            <th>
                                <bs-textbox placeholder="Receipt Number" v-model="filter.ReceiptNumberContains" @keyup="search()" />
                            </th>
                            <th>
                                <bs-date-picker placeholder="Invoice Date From" v-model="filter.InvoiceDateFrom" @change="search()" />
                                <bs-date-picker placeholder="Invoice Date Until" v-model="filter.InvoiceDateUntil" @change="search()" />
                            </th>
                            <th>
                                <bs-date-picker placeholder="Submit Date From" v-model="filter.SubmitDateFrom" @change="search()" />
                                <bs-date-picker placeholder="Submit Date Until" v-model="filter.SubmitDateUntil" @change="search()" />
                            </th>
                            <th>
                                <bs-date-picker placeholder="Delivery Date From" v-model="filter.DeliveryDateFrom" @change="search()" />
                                <bs-date-picker placeholder="Delivery Date Until" v-model="filter.DeliveryDateUntil" @change="search()" />
                            </th>
                            <th>
                                <bs-currency placeholder="Qty From" v-model="filter.QtyFrom" @blur="search()" />
                                <bs-currency placeholder="Qty Until" v-model="filter.QtyUntil" @blur="search()" />
                            </th>
                            <th>
                                <bs-textbox placeholder="Unit" v-model="filter.UnitCodeContains" @keyup="search()" />
                            </th>
                            <th>
                                <bs-currency placeholder="Total Price From" v-model="filter.TotalPriceFrom" @blur="search()" />
                                <bs-currency placeholder="Total Price Until" v-model="filter.TotalPriceUntil" @blur="search()" />
                            </th>
                            <th></th>
                            <th>
                                <bs-textbox placeholder="SO Number" v-model="filter.SONumberContains" @keyup="search()" />
                            </th>
                            <th>
                                <bs-textbox placeholder="PR Number" v-model="filter.PRNumberContains" @keyup="search()" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="data != null && data.length > 0">
                            <tr v-for="item in data" :key="item.invoiceDetailId + (item.selected?'t':'f')">
                                <td>
                                    <bs-checkbox v-model="item.selected" @change="checkData(item)" /> 
                                </td>
                                <td>{{item.vendorInvoiceNo}}</td>
                                <td>{{ item.receiptNumber }}</td>
                                <td><view-date v-model="item.invoiceDate" /></td>
                                <td><view-date v-model="item.submitDate" /></td>
                                <td><view-date v-model="item.deliveryDate" /></td>
                                <td><bs-plain-number v-model="item.qty" /></td>
                                <td>{{ item.unitCode }}</td>
                                
                                <td><bs-plain-number v-model="item.totalPrice" /></td>
                                <td>USD</td>
                                <td>{{item.soNumber}}</td>
                                <td>{{item.prNumber}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-secondary" @click="add"><i class="bi bi-plus-circle"></i> Add</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
    
</template>

<script>
import PurchaseOrderService from '@/services/purchase-order-service.js';
import BsCheckbox from '../../plugins/components/BsCheckbox.vue';

export default {
  components: { BsCheckbox },
    name: 'InvoiceReceiptPopup',
    data: function() {
        return {
            filter: {
                codeContains: '',
                page: 1,
                perPage: 10,
                orderByProperty: 'invoiceDetailId',
                orderType: 0
            },
            checkAll: false,
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            selectPerPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: Array(),
            selectedInvoiceIds: Array(),
            checkedItems: []
        }
    },
    props: {
        vendorId: Number
    },
    methods: {
        async onPopupClosed() {
            
        },
        async showPopup() {
            this.$refs.form.showPopup();
            await this.loadData();
        },
        async close() {
            this.$refs.form.closePopup();
        },
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async reorder(property, orderType) {
            this.filter.orderByProperty = property;
            this.filter.orderType = orderType;
            this.loadData();
        },
        async search() {
            this.filter.page = 1;
            this.loadData();
        },
        async onChangeSelectPerPage() {
            this.filter.perPage = this.selectPerPage;
            this.loadData();
        },
        async add() 
        {
            if (this.checkedItems === undefined || this.checkedItems === null || this.checkedItems.length === 0) return;
            
            for (let i = 0; i < this.checkedItems.length; i++)
            {
                const rowData = this.checkedItems[i];
                if (!rowData.selected) continue;
                this.$emit('addRowData', rowData);
            }

            this.close();
        },
        async loadData() {
            this.filter.vendorIdEquals = this.vendorId;
            
            const result = await PurchaseOrderService.getListInvoiceReceipt(this.filter);
            if (result.status) {
                this.errorMessages = null; 
            }
            else {
                this.errorMessages = result.errorMessages;
            }

            const data = result.data;
            this.pageNumber = data.pageNumber;
            this.perPage = this.selectPerPage;

            this.totalPage = data.totalPage;
            this.totalRow = data.totalRow;
            this.data = Array();
            this.data = data.data;

            for (let i = 0; i < this.checkedItems.length; i++) {

                var testlagi = this.checkedItems[i];
                var searchData = this.data.findIndex(e => e.invoiceDetailId === testlagi.invoiceDetailId);
                if (searchData > -1) {
                    var getData = this.data[searchData];
                    getData.selected = true;
                    this.data[searchData] = getData;
                }
            }
        },
        async checkData(rowData) {
            var testObject = rowData;
            const foundIndex = this.checkedItems.findIndex(e => e.invoiceDetailId === testObject.invoiceDetailId);
            if (foundIndex > -1) {
                this.checkedItems.splice(foundIndex, 1);
            } else {
                this.checkedItems.push(testObject);
            }
        },
        async onChangeCheckAll() 
        {
            if (this.data == null || this.data.length == 0) 
            {
                return;
            }

            for (let i = 0; i < this.data.length; i++) 
            {
                this.data[i].selected = this.checkAll;
                
                if (this.checkAll == true) {
                    var testObjectTrue = this.data[i];
                    const foundIndex = this.checkedItems.findIndex(e => e.invoiceDetailId === testObjectTrue.invoiceDetailId);
                    if (foundIndex == -1) {
                        this.checkedItems.push(testObjectTrue);
                    } 
                }

                if (this.checkAll == false) {
                    var testObjectFalse = this.data[i];
                    const foundIndex = this.checkedItems.findIndex(e => e.invoiceDetailId === testObjectFalse.invoiceDetailId);
                    if (foundIndex > -1) {
                        this.checkedItems.splice(foundIndex, 1);
                    }
                }
                
            }
        }
    }
}
</script>
