import HttpService from './http-service.js';
const httpService = new HttpService();
import formatter from './formatter.js';

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`contracts`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`contracts/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async create(model) {
        model = this._setDefaultValue(model);
        const response = await httpService.post('contracts', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        model = this._setDefaultValue(model);
        const response = await httpService.update('contracts', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('contracts', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    default() {
        return {
            id: 0,
            soldToId: "",
            soldToCode: "",
            soldToName: "",
            soldToLabel: "",
            contractNumber: "",
            validFrom: null,
            validUntil: null
        }
    },

    _setDefaultValue(jsonData) {
        return jsonData;
    },

    _toObject(jsonData) {
        const result = this.default();

        result.id = jsonData.id;
        result.soldToId = jsonData.soldToId;
        result.soldToName = jsonData.soldToName;
        result.soldToLabel = jsonData.soldToCode + ' - ' + jsonData.soldToName;
        result.contractNumber = jsonData.contractNumber;
        result.validFrom = formatter.stringToDate(jsonData.validFrom);
        result.validUntil = formatter.stringToDate(jsonData.validUntil);
        return result;
    }
});