import BsTextbox from './components/BsTextbox.vue';
import BsTextarea from './components/BsTextarea.vue';
import BsCheckbox from './components/BsCheckbox.vue';
import BsPopup from './components/BsPopup.vue';
import BsCurrency from './components/BsCurrency.vue';
import BsAdminLayout from './components/BsAdminLayout.vue';
import BsMenu from './components/BsMenu.vue';
import BsDropdownMenu from './components/BsDropdownMenu.vue';
import BsDropdownMenuItem from './components/BsDropdownMenuItem.vue';
import BsMenuItem from './components/BsMenuItem.vue';
import BsSelect from './components/BsSelect.vue';
import BsSearchForm from './components/BsSearchForm.vue';
import BsPlainText from './components/BsPlainText.vue';
import BsFile from './components/BsFile.vue';
import BsDatePicker from './components/BsDatePicker.vue';
import BsOrdering from './components/BsOrdering.vue';
import BsPagination from './components/BsPagination';
import BsErrorMessage from './components/BsErrorMessage';
import BsValidationControl from './components/BsValidationControl';
import BsPlainNumber from './components/BsPlainNumber';
import BsInputError from './components/BsInputError';

export default {
    install: (app) => {
        app.component('bs-textbox', BsTextbox);
        app.component('bs-textarea', BsTextarea);
        app.component('bs-checkbox', BsCheckbox);
        app.component('bs-popup', BsPopup);
        app.component('bs-currency', BsCurrency);
        app.component('bs-admin-layout', BsAdminLayout);
        app.component('bs-menu', BsMenu);
        app.component('bs-dropdown-menu', BsDropdownMenu);
        app.component('bs-dropdown-menu-item', BsDropdownMenuItem);
        app.component('bs-menu-item', BsMenuItem);
        app.component('bs-select', BsSelect);
        app.component('bs-search-form', BsSearchForm);
        app.component('bs-plain-text', BsPlainText);
        app.component('bs-file', BsFile);
        app.component('bs-date-picker', BsDatePicker);
        app.component('bs-ordering', BsOrdering);
        app.component('bs-pagination', BsPagination);
        app.component('bs-error-message', BsErrorMessage);
        app.component('bs-validation-control', BsValidationControl);
        app.component('bs-plain-number', BsPlainNumber);
        app.component('bs-input-error', BsInputError);
    }
}