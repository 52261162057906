<template>
  <bs-select v-model="text" @onSelect="selectUnit" @onSearch="filterUnit" :options="list">
    <template v-slot:default="props">
      <div>{{props.item.code}} - {{props.item.description}}</div>
    </template>
  </bs-select>
</template>
<script>
import MasterCurrenciesService from "@/services/master-currencies-service";

export default {
  name: 'CurrencyCombo',
  data: function() {
    return {
      text: '',
      list: null,
      hasFocus: false
    }
  },
  props: {
    modelValue: Number,
    textDefault: String
  },
  updated() {
    if (!this.hasFocus)
    {
      this.hasFocus = true;
      this.setComboLabel(this.modelValue);
    }
  },
  emits: ['update:modelValue'],
  methods: {
    async setComboLabel(id) {
      if (id === undefined || id === null || id === '' || id < 1) {
        this.text = '';
        return;
      }

      const obj = await MasterCurrenciesService.getObject(id);
      if (obj.status) {
        this.text = `${obj.data.code} - ${obj.data.description}`;
      }
      else {
        this.text = '';
        alert(`Failed load unit with id = '${id}'.`);
      }
    },
    selectUnit(item) {
      if (item == null) {
        // this.text = '';
        this.$emit('update:modelValue', null);
      }
      else
      {
        // this.text = item.code;
        this.$emit('update:modelValue', item.id);
      }
      this.setComboLabel(item == null ? null : item.id);
    },
    async filterUnit(value) {
      const filter = {
        codeContains: value
      };

      const result = await MasterCurrenciesService.getList(filter);
      let arrayResult = null;
      if (result.status) {
        if (result.data != null && result.data.data != null)
        {
          arrayResult = result.data.data;
        }
      }

      this.list = arrayResult;
    }
  }
}
</script>