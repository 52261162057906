<template>
    <div class="card card-stats">
        <div :class="classType">
            <div class="card-icon">
                <slot name="icon"></slot>
            </div>
            <p class="card-category">
                <slot name="title"></slot>
            </p>
            <h3 class="card-title">
                <slot name="counter"></slot>
            </h3>
        </div>
    </div>
</template>

<style scoped>
    .card-stats .card-header.card-header-icon, .card-stats .card-header.card-header-text {
        text-align: right;
        font-weight: bold;
        font-size: 20px;
    }
    .card .card-warning .card-icon {
        background: linear-gradient(60deg,#ffa726,#fb8c00);
        color: white;
    }
    .card .card-danger .card-icon {
        background: linear-gradient(60deg,#aa0c07,#c50101);
        color: white;
    }
    .card .card-success .card-icon {
        background: linear-gradient(60deg,#08b611,#02a526);
        color: white;
    }
    .card .card-primary .card-icon {
        background: linear-gradient(60deg,#0e76ff,#008afb);
        color: white;
    }
    .card-icon {
        border-radius: 3px;
        background-color: #999;
        padding: 15px;
        margin-top: -20px;
        margin-right: 15px;
        float: left;
        font-size: 40px;
    }
</style>

<script>
export default {
    props: {
        type: String
    },
    name: "DashboardCard",
    computed: {
        classType() {
            let cardType = "card-primary";
            switch (this.type) {
                case "warning": cardType = "card-warning"; break;
                case "primary": cardType = "card-primary"; break;
                case "success": cardType = "card-success"; break;
                case "danger": cardType = "card-danger"; break;
                default: cardType = "card-primary";break;   
            }
            return "card-header card-header-icon " + cardType; 
        }
    }
}
</script>