<template>
    <slot></slot>
    <div id="validationServer03Feedback" class="invalid-feedback">
        <ul>
            <li>Please provide a valid city.</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "BsValidationControl",
    props: {
        error: Object,
        key: String
    },
    updated(){
        this.$slots.default().forEach(value => {
            console.log(value);
        });
        console.log(this.$el.querySelector('input'));
    },
    mounted() {
        
        console.log('mounted');
    }
}
</script>

<style>

</style>