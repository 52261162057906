import HttpService from "@/services/http-service";
import {OPSI_STATUS} from "@/plugins/constant";

const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`BasePrice`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`BasePrice/${id}`);
        if (!response.status) {
            response.data = this.defaultForEdit();
            return response;
        }
        response.data = this._toObjectForEdit(response.data);
        return response;
    },

    async generate(model) {
        return await httpService.post('BasePrice/Generate', model);
    },

    async update(model, id) {
        model = this._setDefaultValueForEdit(model);
        const response = await httpService.update('BasePrice', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('BasePrice', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async deleteGroup(idprofile,month,year) {
        const response = await httpService.deleteForBasePrice('BasePrice/group',idprofile,month,year);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },


    default() {
        return {
            id: 0,

            profilId: 0,
            profilNama: null,

            generated:[]
        };
    },

    defaultForEdit() {
        return {
            id: 0,

            profilId: 0,
            profilNama: null,

            remarks: null,
            quantity: 0,
            status: 0,
            validityStart: null,
            validityEnd: null
        };
    },

    modelFormElement(){
      return {
          id:0,
          profilId: 0,
          remarks: null,
          quantity: 0,
          status: 0,
          validityStart: null,
          validityEnd: null
      }
    },


    _setDefaultValueForEdit(jsonData) {
        if (jsonData === undefined || jsonData === null) {
            return this.defaultForEdit();
        }

        if (jsonData.quantity && typeof jsonData.quantity === "string"){
            jsonData.quantity = parseFloat(jsonData.quantity);
        }

        return jsonData;
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.remarks = jsonData.remarks;
        result.quantity = jsonData.quantity;
        result.validityStart = jsonData.validityStart;
        result.validityEnd = jsonData.validityEnd;

        result.profilId= jsonData.profilId;
        result.profilNama= jsonData.profilNama;
        result.currencyNama = jsonData.currencyNama
        result.currencyUnitLabel= `${jsonData.currencyNama}/${jsonData.unitNama}`;

        result.status = jsonData.status;
        result.statusNama = this.getNameStatus(result.status);

        return result;
    },

    _toObjectForEdit(jsonData) {
        const result = this.defaultForEdit();

        result.id = jsonData.id;
        result.remarks = jsonData.remarks;
        result.quantity = jsonData.quantity;
        result.validityStart = new Date(jsonData.validityStart);
        result.validityEnd = new Date(jsonData.validityEnd);

        result.profilId= jsonData.profilId;
        result.profilNama= jsonData.profilNama;
        result.currencyNama = jsonData.currencyNama;
        result.unitNama = jsonData.unitNama;

        result.status = jsonData.status;
        result.statusNama = this.getNameStatus(result.status);

        return result;
    },

    getNameStatus(value) {
        let result = '-';
        if (!(value === undefined || value === null || value === '' )) {
            result = OPSI_STATUS.filter((item) => item.value === value)[0].label
        }
        return result;
    }
});