import SecurityService from '@/services/security-service';
import axios from 'axios';

export default class HttpService 
{
    _api = process.env.VUE_APP_AVIATION_API;
    _authServer = new SecurityService();

    setApi(api) {
        this._api = api;
    }

    _apiPath(url) {
        return `${this._api}/${url}`;
    }

    async _getHeaders() 
    {
        let headers = {
            "Content-Type": "application/json"
        };

        if (this._authServer !== undefined && this._authServer !== null) {
            const token = await this._authServer.getAccessToken();
            if (token !== undefined && token !== null)
            {
                headers.Authorization = `Bearer ${token}`;          
            }
        }

        return headers;
    }

    async _getHeadersFile() 
    {
        let headers = {
            
        };

        if (this._authServer !== undefined && this._authServer !== null) {
            const token = await this._authServer.getAccessToken();
            if (token !== undefined && token !== null)
            {
                headers.Authorization = `Bearer ${token}`;          
            }
        }

        return headers;
    }

    async post(url, jsonPayload) 
    {
        const api = this._apiPath(url);
        const headers = await this._getHeaders();
        let response = null;
        try {
            response = await axios.post(api, JSON.stringify(jsonPayload), {
                headers: headers
            });
        } catch (e) {
            if (e.response !== undefined && e.response !== null) {
                response = e.response;
            }
        }
        
        return await this._processResponse(response);
    }

    async get(url, params) {
        let filter = "";
        if (params !== undefined && params !== null) {
            filter = "?";
            const filterParameter = Object.keys(params).map(function(k) {
                let value = params[k];
                if (params[k] instanceof Date) {
                    value = value.toISOString();
                }
                if (params[k] === undefined || params[k] === null || params[k] === ''){
                    value = '';
                }
                return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`; 
            }).join('&');
            filter = `?${filterParameter}`;
        }

        const api = `${this._apiPath(url)}${filter}`;
        const headers = await this._getHeaders();
        
        let response = null;
        
        try {
            response = await axios.get(api, {
                headers: headers
            });
        }
        catch (e) {
            if (e.response !== undefined && e.response !== null) {
                response = e.response;
            }
        }

        return await this._processResponse(response);
    }

    async put(url, jsonData)
    {
        const headers = await this._getHeaders();
        const api = this._apiPath(url);

        let response = null; 
        try {
            response = await axios.put(api, JSON.stringify(jsonData), {
                headers: headers
            });
        }
        catch (e) {
            if (e.response !== undefined && e.response !== null) {
                response = e.response;
            }
        }

        return await this._processResponse(response);
    }

    async update(url, id, jsonData)
    {
        const urlId = `${url}/${id}`;
        return await this.put(urlId, jsonData);
    }

    async delete(url, id) {
        const headers = await this._getHeaders();
        const api = `${this._apiPath(url)}/${id}`;

        let response = null;
        
        try {
            response = await axios.delete(api, {
                headers: headers
            });
        }
        catch (e) {
            if (e.response !== undefined && e.response !== null) {
                response = e.response;
            }
        }

        // const response = await fetch(api, {
        //     headers: headers,
        //     method: "DELETE"
        // });

        return await this._processResponse(response);
    }

    async deleteForBasePrice(url,id,month,year) {
        const headers = await this._getHeaders();
        const api = `${this._apiPath(url)}/${id}/${month}/${year}`;

        let response = null;

        try {
            response = await axios.delete(api, {
                headers: headers
            });
        }
        catch (e) {
            if (e.response !== undefined && e.response !== null) {
                response = e.response;
            }
        }

        // const response = await fetch(api, {
        //     headers: headers,
        //     method: "DELETE"
        // });

        return await this._processResponse(response);
    }

    async downloadExcelWithFilter(url, params) {
        let filter = "";
        if (params !== undefined && params !== null) {
            filter = "?";
            const filterParameter = Object.keys(params).map(function(k) {
                let value = params[k];
                if (params[k] instanceof Date) {
                    value = value.toISOString();
                }
                if (params[k] === undefined || params[k] === null || params[k] === ''){
                    value = '';
                }
                return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`; 
            }).join('&');
            filter = `?${filterParameter}`;
        }

        const api = `${this._apiPath(url)}${filter}`;
        const headers = await this._getHeadersFile();
        const response = await fetch(api, {
            headers: headers,
            method: "GET"
        });

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;

        const contentDisposition = response.headers.get('content-disposition');
        let fileNameDownload = null;
        if (contentDisposition !== undefined && contentDisposition !== null) 
        {
            const fileNameInfo = contentDisposition.split(';').filter(f => f.trim().includes("filename="));
            if (fileNameInfo !== undefined && fileNameInfo !== null && fileNameInfo.length > 0) {
                const fileName = fileNameInfo[0].split('=')[1];
                fileNameDownload = fileName;
            }
        }
        
        if (fileNameDownload == null) {
            fileNameDownload = 'file.xlsx';
        }
        
        a.download = fileNameDownload;
        
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);

        return true;
    }

    async downloadFile(url) 
    {
        const api = this._apiPath(url);
        const headers = await this._getHeadersFile();
        const response = await fetch(api, {
            method: "GET",
            headers: headers
        });

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'template.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    }

    async _processResponse(response) 
    {
        let message = null;
        const result = {
            status: false,
            errorMessages: null,
            data: null,
            response: response
        };
        
        if (response.status === 401) {
            result.errorMessages = {
                "_ERRORS_": [
                    "You don't have privilege."
                ]
            }
            return  result;
        }

        if (response.status === 200 || response.status === 400) {
            try {
                const json = await response.data;
                if (json === undefined) throw 'response body is not valid.';
                if (json.status === undefined) throw 'property status not found on response body.';
                if (json.data === undefined) throw 'property data not found on response body.'
                if (json.errorMessages === undefined) throw 'property errorMessages not found on response body.';

                result.status = json.status;
                result.errorMessages = json.errorMessages;
                result.data = json.data;

                return result;
            }
            catch (e) 
            {
                message = e;
            }
        }
        
        result.status = false;
        result.errorMessages = {
            "_ERRORS_": [
                message
            ]
        };
        result.data = null;
        return result;
    }
}