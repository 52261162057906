import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import BsPlugin from '@/plugins/BsPlugin.js'
import "bootstrap"
import '@/assets/css/main.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "flatpickr/dist/flatpickr.min.css"
import ViewDate from '@/components/ViewDate'
import ViewDateTime from '@/components/ViewDateTime'
import AirlineCombo from '@/components/AirlineCombo'
import CustomerCombo from '@/components/CustomerCombo'
import ContractCombo from '@/components/ContractCombo'
import LocationCombo from '@/components/LocationCombo'
import UnitCombo from '@/components/UnitCombo'
import VendorCombo from '@/components/VendorCombo'
import InvoiceReceiptPopup from '@/components/popup/InvoiceReceiptPopup'
import YearCombo from '@/components/YearCombo'
import MonthCombo from '@/components/MonthCombo'
import RequestInterceptor from '@/components/RequestInterceptor'
import ReviewInvoiceStatusCombo from '@/components/ReviewInvoiceStatusCombo'
import CurrencyCombo from '@/components/CurrencyCombo'
import TimeRangeCombo from '@/components/TimeRangeCombo.vue'
import StartDayCombo from '@/components/StartDayCombo.vue'
import TermsOfPaymentCombo from "@/components/TermsOfPaymentCombo.vue";
import BasePriceCombo from "@/components/BasePriceCombo.vue";
import axios from 'axios';

createApp(App)
    .use(store)
    .use(router)
    .use(BsPlugin)
    .component('request-interceptor', RequestInterceptor)
    .component('view-date', ViewDate)
    .component('view-date-time', ViewDateTime)
    .component('airline-combo', AirlineCombo)
    .component('customer-combo', CustomerCombo)
    .component('contract-combo', ContractCombo)
    .component('location-combo', LocationCombo)
    .component('unit-combo', UnitCombo)
    .component('vendor-combo', VendorCombo)
    .component('invoice-receipt-popup', InvoiceReceiptPopup)
    .component('year-combo', YearCombo)
    .component('month-combo', MonthCombo)
    .component('review-invoice-status-combo', ReviewInvoiceStatusCombo)
    .component('currency-combo', CurrencyCombo)
    .component('time-range-combo', TimeRangeCombo)
    .component('start-day-combo', StartDayCombo)
    .component('terms-of-payment-combo', TermsOfPaymentCombo)
    .component('base-price-combo', BasePriceCombo)
    .mount('#app')

axios.interceptors.request.use((config) => {
    store.commit('requestQueueIncrement');
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    store.commit('requestQueueDecrement');
    return response;
}, (error) => {
    store.commit('requestQueueDecrement');
    return Promise.reject(error);
});
