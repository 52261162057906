<template>
    <ul class="list-unstyled ps-0">
        <slot></slot>
    </ul>
</template>

<script>
export default {
    name: 'BsMenu'
}
</script>

<style>
    
</style>