<template>
    {{formatDate}}
</template>
<script>
import formatter from '@/services/formatter';

export default {
    name: "ViewDateTime",
    props: {
        modelValue: Date
    },
    computed: {
        formatDate() {
            return formatter.dateToDateTimeFormat(this.modelValue);
        }
    }
}
</script>