<template>
    <li>
        <a v-bind="$attrs"
                :href="(baseUrl + to)"
                :class="'link-dark rounded'"
        >
                <slot />
        </a>
    </li>
</template>

<script>
// import { RouterLink } from 'vue-router'
export default {
    name: 'BsDropdownMenuItem',
    data: function() {
        return {
            baseUrl: document.location.origin + process.env.VUE_APP_BASE_URL // process.env.VUE_APP_DOMAIN + process.env.VUE_APP_BASE_URL
        };
    },
    inheritAttrs: false,
    // data: function() {
    //     return {
    //         activeClass: 'menu-active',
    //         inactiveClass: 'menu-not-active'
    //     };
    // },
    props: {
        to: String
        // ...RouterLink.props
    }
}
</script>

<style>
</style>