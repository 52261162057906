<template>
    <div class="offcanvas offcanvas-start" id="left-menu">
        <div class="offcanvas-header">
            <div class="offcanvas-title" id="offcanvasLabel">
                <slot name="left-menu-header"></slot>
            </div>
            
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <slot name="app-logo"></slot>
            <slot name="left-menu"></slot>
        </div>
    </div>

    <nav class="navbar fixed-top navbar-dark bg-primary">
        <div class="container-fluid">
            <ul class="navbar-nav offcanvas-toggler">
                <li class="nav-item">
                    <a class="nav-link" role="button" href="#left-menu" data-bs-toggle="offcanvas">
                        <span class="navbar-toggler-icon"></span>
                    </a>
                </li>
            </ul>
            <router-link class="navbar-brand me-auto mb-2 mb-lg-0" to="/">
                <slot name="app-title"></slot>
            </router-link>
        </div>
    </nav>

    <div class="preloader" v-if="onLoad">
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
    
    <div class="container">
        <slot name="content"></slot>
    </div>
    
</template>

<script>
export default {
    name: "BsAdminLayout",
    computed: {
        onLoad: function() {
            return this.$store.state.requestCounter;
        }
    }
}
</script>

<style scoped>
    .left-menu {
        min-height: 100vh;
    }
    .container {
        margin-top: 75px;
    }
    .offcanvas-toggler {
        margin-right: 10px;
    }
    .preloader { 
        z-index: 1;
        width:100vw;
        position:fixed;
        top:52px
    }
    .preloader > .progress {
        border-radius: 0px;
    }
</style>