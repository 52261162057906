<template>
    <div v-if="errors != null && errors[keyError]" class="invalid-feedback backend-validation">
        <ul>
            <li v-for="(item, index) in errors[keyError]" :key="index">
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'BsInputError',
    props: ['errors','keyError']
}
</script>