import { createStore } from 'vuex'

export default createStore({
  state: {
    requestCounter: 0,
    progressCounter: 0,
  },
  mutations: {
    requestQueueIncrement(state) {
      state.requestCounter++;
    },
    requestQueueDecrement(state) {
      state.requestCounter--;
    },
    progressCounterQueueIncrement(state) {
      state.progressCounter++;
    },
    progressCounterQueueDecrement(state) {
      state.progressCounter--;
    }
  },
  actions: {

  },
  modules: {
    
  }
})
