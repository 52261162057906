<template>
    <bs-select v-model="text" @onSelect="selectMonth" @onSearch="filterMonth" :options="list">
        <template v-slot:default="props">
            <div>{{props.item.label}}</div>
        </template>
    </bs-select>
</template>
<script>
export default {
    name: 'MonthCombo',
    data: function() {
        const monthData = [
            {
                id: 1,
                label: 'Jan'
            },
            {
                id: 2,
                label: 'Feb'
            },
            {
                id: 3,
                label: 'Mar'
            },
            {
                id: 4,
                label: 'Apr'
            },
            {
                id: 5,
                label: 'May'
            },
            {
                id: 6,
                label: 'Jun'
            },
            {
                id: 7,
                label: 'Jul'
            },
            {
                id: 8,
                label: 'Aug'
            },
            {
                id: 9,
                label: 'Sep'
            },
            {
                id: 10,
                label: 'Oct'
            },
            {
                id: 11,
                label: 'Nov'
            },
            {
                id: 12,
                label: 'Des'
            },
        ];
        return {
            text: '',
            list: null,
            hasFocus: false,
            combo: monthData
        }
    },
    props: {
        modelValue: Number,
        textDefault: String
    },
    updated() {
        // if (this.textDefault != null && this.textDefault != "" && !this.hasFocus) 
        // {
        //     this.text = this.textDefault;
        //     this.hasFocus = true;
        // }
    },
    mounted() {
        this.setComboLabel(this.modelValue);
    },
    emits: ['update:modelValue'],
    methods: {
        async setComboLabel(id) {
            if (id === undefined || id === null || id === '' || id < 1) 
            {
                return;
            }

            let result = await this.combo.filter(f => f.id.toString() == id);
            this.text = result[0].label;
        },
        selectMonth(item) {
            if (item == null) {
                this.text = '';
                this.$emit('update:modelValue', null);
            }
            else 
            {
                this.text = item.label;
                this.$emit('update:modelValue', item.id);
            }
            this.setComboLabel(item == null ? null : item.id);
        },
        async filterMonth(value) {
            const result = await this.combo.filter(f => value === undefined || value === null || value === '' || f.label.toString().includes(value));
            this.list = result;
        }
    }
}
</script>