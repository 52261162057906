<template>
  <bs-admin-layout :onProgress="onProgress">
    <template v-slot:app-title>
      <img src="@/assets/bg-white.png" style="height:26px" />
    </template>
    <template v-slot:left-menu-header>
      <img src="@/assets/pertamina_logo.png" style="width:100%" />
    </template>
    <template v-slot:left-menu>
        <bs-menu>
            <bs-menu-item to="/">Home</bs-menu-item>
            <bs-dropdown-menu v-show="isAdmin">
                <template v-slot:menu-title>
                    Administrasi
                </template>
                <template v-slot:dropdown-list>
                    <bs-dropdown-menu-item to="/master-airlines">Master Airlines</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-customer">Master Customer</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-vendor">Master Vendor</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-currencies">Master Currencies</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-unit">Master Unit</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-location">Master Location</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-time-range">Master Time Range</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-fee">Master Fee</bs-dropdown-menu-item>
                </template>
            </bs-dropdown-menu>
            <bs-dropdown-menu>
                <template v-slot:menu-title>
                    Master Data
                </template>
                <template v-slot:dropdown-list>
                    <bs-dropdown-menu-item to="/mapping-aircraft">Mapping Customer</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/mapping-price">Mapping Price Per Customer Location</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-kurs">Master Kurs</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/contracts">Contracts</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/base-price-profile">Base Price Profile</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/base-price">Base Price</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/fee-profile">Fee Profile</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/master-fee">Fee</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/spsh">Mapping SP-SH-Price</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/fee">Fee</bs-dropdown-menu-item>
                </template>
            </bs-dropdown-menu>
            <bs-dropdown-menu>
                <template v-slot:menu-title>
                    Transaction
                </template>
                <template v-slot:dropdown-list>
                    <bs-dropdown-menu-item to="/review-invoice-vendor">Review Invoice Vendor</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/progress-payment">Monitoring Invoice Conco Delco</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/purchase-order">Create PO</bs-dropdown-menu-item>
                </template>
            </bs-dropdown-menu>
            <bs-dropdown-menu>
                <template v-slot:menu-title>
                    Report
                </template>
                <template v-slot:dropdown-list>
                    <bs-dropdown-menu-item to="/report-realisasi-conco-delco">Report Realisasi Conco Delco</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/report-pembayaran-vs-penagihan">Report Pembayaran vs Penagihan</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/report-invoice-detail">Invoice Detail</bs-dropdown-menu-item>
                    <bs-dropdown-menu-item to="/report-price-overseas">Report Price Overseas</bs-dropdown-menu-item>
                </template>
            </bs-dropdown-menu>
            <bs-menu-item to="/signout">Sign Out</bs-menu-item>
        </bs-menu>
    </template>
    <template v-slot:content>
      <router-view />
    </template>
  </bs-admin-layout>
</template>

<script>
    import SecurityService from "@/services/security-service.js";
    export default {
        data: function () {
            return {
                isAdmin: false
            }
        },
        mounted() {
            this.checkRole();
        },
        methods: {
            async checkRole() {
                const securityService = new SecurityService();

                if (await securityService.allowAccessAdmin("MASTER","READ")) {
                    this.isAdmin = true;
                } else {
                    this.isAdmin = false;
                }
            }
        },
      computed: {
        onProgress: function() {
          return this.$store.state.requestCounter;
        }
      }
}
</script>

<style>

</style>