<template>
    <input type="textbox" :value="modelValue" @input="onChangeModelValue($event)" @focus="onFocus" @blur="onBlur" ref="comboFilter" class="form-control form-control-sm select-control" :disabled="disabled"/>
    <div class="combo-container" ref="comboContainer" style="display:none">
        <template v-if="hasEvents">
            <div v-for="(item, index) in options" :key="index" class="item" @mousedown="selectItem(item)">
                <slot :item="item"></slot>
            </div>
        </template>
        <template v-if="hasEvents == false">
            <div class="item">There is no data</div>
        </template>
        
    </div>
    <!-- <select :value="modelValue" @input="onChangeModelValue($event)" class="form-select form-select-sm">
        <slot></slot>
    </select> -->
</template>

<script>
export default {
    name: "BsTextbox",
    data: function() {
        return {
            selectedItem: null
        };
    },
    props: {
        modelValue: String,
        options: Array,
        disabled:{
          type: Boolean,
          default: false
        }
    },
    emits: ['onSelect', 'onSearch','update:modelValue'],
    computed: {
        hasEvents() {
            return this.options != null && this.options.length > 0;
        }
    },
    methods: {
        onChangeModelValue(event) {
            const comboFilter = this.$refs.comboFilter;
            const comboContainer = this.$refs.comboContainer;
            comboContainer.style.width = comboFilter.clientWidth + "px";
            this.$emit('update:modelValue', event.target.value);
            this.$emit('onSearch', event.target.value);
            this.selectedItem = null;
        },
        onFocus() {
            this.$emit('onSearch', '');
            this.showOptions();  
        },
        onBlur() {
            if (this.selectedItem == null) 
            {
                this.$emit('onSelect', null);
            }
            this.hideOptions();
        },
        showOptions() {
            const comboFilter = this.$refs.comboFilter;
            const comboContainer = this.$refs.comboContainer;
            comboContainer.style.width = comboFilter.clientWidth + "px";
            comboContainer.style.display = "block";
        },
        hideOptions() {
            const comboContainer = this.$refs.comboContainer;
            comboContainer.style.display = "none";
        },
        selectItem(item) 
        {
            this.selectedItem = item;
            this.$emit('onSelect', item);
        }
    }
}
</script>

<style scoped>
    .combo-container {
        position: absolute;
        background: white;
        box-shadow: 0px 0px 0.1px 1px #bfbfbf;
        z-index: 9999;
    }
    .item {
        padding: 10px;
    }
    .item:hover {
        background: #F3F3F3;
    }
</style>>
