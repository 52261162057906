<template>
    <span @click="changeOrderType" style="cursor: pointer;">
        <slot></slot> &nbsp;
        <i v-if="orderType == 0" class="bi bi-chevron-down"></i>
        <i v-if="orderType == 1" class="bi bi-chevron-up"></i>
    </span>
</template>

<script>
export default {
    name: "BsOrdering",
    data: function () {
        return {
            orderType: 1
        }
    },
    props: {
        property: String
    },
    methods: {
        changeOrderType()
        {
            if (this.orderType === 0) {
                this.orderType = 1;
            }
            else 
            {
                this.orderType = 0;
            }
            
            this.$emit('reorder', this.property, this.orderType);
        }
    }
}
</script>

<style scoped>

</style>