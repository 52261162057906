<template>
    <div class="alert alert-danger" v-if="errors != null && errors._ERRORS_">
        <ul>
            <li v-for="(item, index) in errors._ERRORS_" :key="index">
                {{item}}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'BsErrorMessage',
    props: ['errors']
}
</script>