<template>
    <div ref="inputNumber" class="number-position"></div>
</template>

<script>
import accounting from 'accounting';
export default {
    name: "BsPlainNumber",
    props: ['modelValue','decimalPlace'],
    mounted() {
        let value = this.toNumber(this.modelValue);
        value = this.toFormatNumber(value);
        this.$refs.inputNumber.innerHTML = value;
    },
    watch: {
        modelValue: function(newVal) {
            let inputValue = this.toNumber(newVal);
            inputValue = this.toFormatNumber(inputValue);
            this.$refs.inputNumber.innerHTML = inputValue;
        }
    },
    methods: {
        toFormatNumber(value) {
            if (value === undefined || value === null || value === "" || isNaN(value)) 
            {
                return null;
            }

            if (this.decimalPlace !== undefined && this.decimalPlace !== null && !isNaN(this.decimalPlace)) {
                return accounting.formatMoney(value, '', this.decimalPlace, ',', '.');
            }
            return accounting.formatMoney(value, '', 2, ',', '.');
        },
        toNumber(value) {
            if (value === undefined || value === null || value === "" || isNaN(value)) {
                return null;
            }
            return Number(value);
        }
    }
}
</script>

<style>
    /* .number-position {
        text-align: right;
    } */
</style>