<template>
    <textarea :value="modelValue" @input="onChangeModelValue($event)" class="form-control"></textarea>
</template>

<script>
export default {
    name: "BsTextarea",
    props: {
        modelValue: String
    },
    methods: {
        onChangeModelValue(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>

<style>

</style>