<template>
    <div class="row">
        <div class="col-sm-12 dashboard-title">
            Transaction Status Summary
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4 mt-4">
                <dashboard-card @click="toReviewInvoice('Waiting For Approval')" type="warning">
                    <template v-slot:icon>
                        <i class="bi bi-file-earmark-arrow-up"></i>
                    </template>
                    <template v-slot:title>
                        Waiting for Approval
                    </template>
                    <template v-slot:counter>
                        {{ data.waitingForApproval }}
                    </template>
                </dashboard-card>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 mb-4 mt-4">
                <dashboard-card @click="toReviewInvoice('Approved')" type="success">
                    <template v-slot:icon>
                        <i class="bi bi-check-circle"></i>
                    </template>
                    <template v-slot:title>
                        Approved
                    </template>
                    <template v-slot:counter>
                        {{ data.approved }}
                    </template>
                </dashboard-card>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 mb-4 mt-4">
                <dashboard-card @click="toReviewInvoice('Rejected')" type="danger">
                    <template v-slot:icon>
                        <i class="bi bi-x-circle"></i>
                    </template>
                    <template v-slot:title>
                        Rejected
                    </template>
                    <template v-slot:counter>
                        {{ data.rejected }}
                    </template>
                </dashboard-card>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 mb-4 mt-4">
                <dashboard-card @click="toReviewInvoice('IN PROGRESS')" type="primary">
                    <template v-slot:icon>
                        <i class="bi bi-receipt"></i>
                    </template>
                    <template v-slot:title>
                        In Progress
                    </template>
                    <template v-slot:counter>
                        {{ data.inProgress }}
                    </template>
                </dashboard-card>
            </div>
            
            <!-- <div class="col-lg-4 col-md-6 col-sm-6 mb-4 mt-4">
                <dashboard-card type="warning">
                    <template v-slot:icon>
                        <i class="bi bi-clock-history"></i>
                    </template>
                    <template v-slot:title>
                        Done
                    </template>
                    <template v-slot:counter>
                        {{ data.done }}
                    </template>
                </dashboard-card>
            </div> -->

            <div class="col-lg-4 col-md-6 col-sm-6 mb-4 mt-4">
                <dashboard-card @click="toReviewInvoice('DONE')" type="primary">
                    <template v-slot:icon>
                        <i class="bi bi-journal-check"></i>
                    </template>
                    <template v-slot:title>
                        Done
                    </template>
                    <template v-slot:counter>
                        {{ data.done }}
                    </template>
                </dashboard-card>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .dashboard-title {
        widows: 100%;
        font-weight: bold;
        font-size: 24px;
        padding: 10px;
        
        text-align: center;
    }
    
</style>
<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import TransactionStatusSummaryService from '@/services/transaction-status-summary-service'

export default {
    components: {
        DashboardCard
    },
    data: function() {
        return {
            data: TransactionStatusSummaryService.default()
        };
    },
    mounted: async function() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            let response = await TransactionStatusSummaryService.getList(null);
            if (!response.status || response.data === undefined || response.data === null || response.data.data === undefined || response.data.data === null || response.data.data.length === 0) {
                this.data = TransactionStatusSummaryService.default();
                return;
            }

            this.data = response.data.data[0];
        },
        async toReviewInvoice(status) {
            this.$router.push(`/review-invoice-vendor?invoiceStatusDescriptionContains=${status}`);
        }
    }
}
</script>