export const OPSI_START_DAY = [
    {
        value: 1,
        label: 'Senin'
    },
    {
        value: 2,
        label: 'Selasa'
    },
    {
        value: 3,
        label: 'Rabu'
    },
    {
        value: 4,
        label: 'Kamis'
    },
    {
        value: 5,
        label: 'Jumat'
    },
    {
        value: 6,
        label: 'Sabtu'
    },
    {
        value: 7,
        label: 'Minggu'
    }
];

export const OPSI_STATUS = [
    {
        value: 1,
        label: 'OK'
    },
    {
        value: 2,
        label: 'NOT OK'
    },
    {
        value: 3,
        label: 'OK/REVISE'
    }
]

export const OPSI_BULAN = [
    {
        id: 1,
        label: 'Jan'
    },
    {
        id: 2,
        label: 'Feb'
    },
    {
        id: 3,
        label: 'Mar'
    },
    {
        id: 4,
        label: 'Apr'
    },
    {
        id: 5,
        label: 'May'
    },
    {
        id: 6,
        label: 'Jun'
    },
    {
        id: 7,
        label: 'Jul'
    },
    {
        id: 8,
        label: 'Aug'
    },
    {
        id: 9,
        label: 'Sep'
    },
    {
        id: 10,
        label: 'Oct'
    },
    {
        id: 11,
        label: 'Nov'
    },
    {
        id: 12,
        label: 'Des'
    },
]

export const OPSI_DUMMY_TOPS = [
    {
        id: 1,
        label: 'Dummy ToP 1'
    },
    {
        id: 2,
        label: 'Dummy ToP 2'
    },
    {
        id: 3,
        label: 'Dummy ToP 3'
    }
]