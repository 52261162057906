<template>
    <bs-select v-model="text" @onSelect="selectCustomer" @onSearch="filterCustomer" :options="list">
        <template v-slot:default="props">
            <div>{{props.item.code}} - {{props.item.name}}</div>
        </template>
    </bs-select>
</template>
<script>
import CustomerService from '@/services/customer-service.js';
export default {
    name: 'CustomerCombo',
    data: function() {
        return {
            text: '',
            list: null,
            hasFocus: false,
        }
    },
    props: {
        modelValue: Number,
        textDefault: String,
        byCode: {
            default: false,
            type: Boolean
        }
    },
    updated() {
        if (!this.hasFocus) 
        {
            // this.text = this.textDefault;
            if (this.byCode) this.setComboLabelbyCode(this.modelValue)
            else this.setComboLabel(this.modelValue);
            this.hasFocus = true;
        }
    },
    emits: ['update:modelValue'],
    methods: {
        async setComboLabel(id) {
            if (id === undefined || id === null || id === '' || id < 1) {
                this.text = '';
                return;
            }

            const obj = await CustomerService.getObject(id);
            if (obj.status) 
            {
                this.text = `${obj.data.code} - ${obj.data.name}`;
            }
            else 
            {
                alert(`Failed Load Customer Combo with id = '${obj.data.id}'.`);
            }
        },
        async setComboLabelbyCode(code) {
            if (code === undefined || code === null || code === '' || code < 1) {
                this.text = '';
                return;
            }

            let filter = {
                CodeContains: code
            };
            const obj = await CustomerService.getList(filter);
            if (obj.status)
            {
                this.text = `${obj.data.data[0].code} - ${obj.data.data[0].name}`;
            }
            else
            {
                alert(`Failed Load Customer Combo with id = '${obj.data.id}'.`);
            }
        },
        selectCustomer(item) {
            if (item == null) {
                this.$emit('update:modelValue', null);
            }
            else 
            {
                if (this.byCode) this.$emit('update:modelValue', item.code);
                else this.$emit('update:modelValue', item.id);
            }
            if (this.byCode) this.setComboLabelbyCode(item == null ? null : item.code);
            else this.setComboLabel(item == null ? null : item.id);
        },
        async filterCustomer(value) {
            const filter = {
                codeOrNameContains: value
            };
            
            const result = await CustomerService.getList(filter);
            let arrayResult = null;
            if (result.status) {
                if (result.data != null && result.data.data != null) 
                {
                    arrayResult = result.data.data;
                }
            }

            this.list = arrayResult;
        }
    }
}
</script>