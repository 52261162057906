<template>
    <bs-select v-model="text" @onSelect="selectVendor" @onSearch="filterVendor" :options="list">
        <template v-slot:default="props">
            <div>{{props.item.code}} - {{ props.item.name }}</div>
        </template>
    </bs-select>
</template>
<script>
import VendorService from '@/services/vendor-service.js';
export default {
    name: 'VendorCombo',
    data: function() {
        return {
            text: '',
            list: null,
            hasFocus: false
        }
    },
    props: {
        modelValue: Number,
        textDefault: String,
    },
    updated() {
      if (!this.hasFocus)
      {
        this.hasFocus = true;
        this.setComboLabel(this.modelValue);
      }
    },
    emits: ['update:modelValue'],
    mounted() {
        this.setComboLabel(this.modelValue);
    },
    methods: {
        async setComboLabel(id) {
            if (id === undefined || id === null || id === '' || id < 1) 
            {
                return;
            }

            let response = await VendorService.getObject(id);
            if (response.status) {
                this.text = `${response.data.code} - ${response.data.name}`;
            }
            else 
            {
                alert(`Failed load vendor with id = '${id}', please reload page.`);
            }
        },
        selectVendor(item) {
            this.$emit('onBeforeValueChanged', item);
            if (item == null) {
                this.$emit('update:modelValue', null);
            }
            else 
            {
                this.$emit('update:modelValue', item.id);
            }
            
            this.setComboLabel(item == null ? null : item.id);
        },
        async filterVendor(value) {
            const filter = {
                codeOrNameContains: value
            };
            
            const result = await VendorService.getList(filter);
            let arrayResult = null;
            if (result.status) {
                if (result.data != null && result.data.data != null) 
                {
                    arrayResult = result.data.data;
                }
            }

            this.list = arrayResult;
        }
    }
}
</script>