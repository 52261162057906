import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async createPO(invoiceIds) {
        const response = await httpService.post('invoiceforpurchaseorders/CreatePO', invoiceIds);

        if (!response.status) {
            response.data = false;
            return response;
        }

        return response;
    },
    async getList(filter) 
    {
        const response = await httpService.get(`purchaseorders`, filter);
        
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++) 
        {
            result.data.push(this._toObject(response.data.data[i]));
        }
        
        response.data = result;
        return response;
    },

    async create(model) {
        model = this._setDefaultValue(model);
        const response = await httpService.post('purchaseorders', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async getListInvoiceReceipt(filter) 
    {
        const response = await httpService.get(`purchaseorders/invoicedetailforpo`, filter);
        
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++) 
        {
            result.data.push(this._toObjectInvoiceReceipt(response.data.data[i]));
        }
        
        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`purchaseorders/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },
    
    default() {
        return {
            id: 0,
            vendorId: 0,
            vendorCode: null,
            vendorName: null,
            poNumber: null,
            errorMessageCreatePONumber: null,
            poCreatedBy: "",
            poCreatedOn: null,
            miroDoc: null,
            miroYear: 0,
            paymentDueDate: null,
            paymentClearing: null,
            purchaseOrderDetails: []
        };
    },

    _setDefaultValue(jsonData) {
        if (jsonData === undefined || jsonData === null) {
            return this.default();
        }

        if (jsonData.vendorId === null || jsonData.vendorId === '') {
            jsonData.vendorId = 0;
        }

        if (jsonData.purchaseOrderDetails === undefined || jsonData.purchaseOrderDetails === null) {
            jsonData.purchaseOrderDetails = new Array();
        }
        
        return jsonData;
    },

    _toObject(jsonData) {
        const result = this.default();

        result.id = jsonData.id;
        result.vendorId = jsonData.vendorId;
        result.vendorCode = jsonData.vendorCode;
        result.vendorName = jsonData.vendorName;
        result.poNumber = jsonData.poNumber;
        result.errorMessageCreatePONumber = jsonData.errorMessageCreatePONumber;
        result.poCreatedBy = jsonData.poCreatedBy;
        result.poCreatedOn = jsonData.poCreatedOn;
        result.miroDoc = jsonData.miroDoc;
        result.miroYear = jsonData.miroYear;
        result.paymentDueDate = jsonData.paymentDueDate;
        result.paymentClearing = jsonData.paymentClearing;
        result.purchaseOrderDetails = jsonData.purchaseOrderDetails;
        
        return result;
    },

    defaultInvoiceReceipt() {
        return {
            invoiceDetailId: null,
            vendorId: null,
            vendorInvoiceNo: null,
            receiptNumber: null,
            invoiceDate: null,
            submitDate: null,
            deliveryDate: null,
            qtyInGal: 0,
            totalPrice: 0,
            soNumber: null,
            prNumber: null,
            selected: false,
        };
    },

    _toObjectInvoiceReceipt(jsonData) {
        const result = this.defaultInvoiceReceipt();

        result.invoiceDetailId = jsonData.invoiceDetailId;
        result.vendorId = jsonData.vendorId;
        result.vendorInvoiceNo = jsonData.vendorInvoiceNo;
        result.receiptNumber = jsonData.receiptNumber;
        result.invoiceDate = jsonData.invoiceDate;
        result.submitDate = jsonData.submitDate;
        result.deliveryDate = jsonData.deliveryDate;
        result.qtyInGal = jsonData.qtyInGal;
        result.qty = jsonData.qty;
        result.unitId = jsonData.unitId;
        result.unitCode = jsonData.unitCode;
        result.totalPrice = jsonData.totalPrice;
        result.soNumber = jsonData.soNumber;
        result.prNumber = jsonData.prNumber;
        result.selected = false;
        
        return result;
    }
});