<template>
    <ul class="pagination pagination-sm">
        <template v-if="pagingData != null && pagingData.length > 0">
            <li v-for="item in pagingData" :key="item.key" :class="'page-item ' + (item.selected ? 'active' : '')">
                <a class="page-link" style="cursor:pointer" @click="changePage(item.pageNumber)" >{{item.label}}</a>
            </li>
        </template>
    </ul>
    <!-- Total {{totalRow}} Rows, Page {{pageNumber}} / {{totalPage}} -->
</template>

<script>
export default {
    name: "BsPagination",
    props: {
        totalRow: Number,
        pageNumber: Number,
        perPage: Number,
        totalPage: Number
    },
    methods: {
        changePage(pageNumber) {
            this.$emit('toPage', pageNumber);
        }
    },
    computed: {
        pagingData() {
            const currentPage = this.pageNumber;
            const totalPage = this.totalPage;
            let arrayResult = new Array();
            let key = 0

            if (currentPage > 1) {
                arrayResult.push({
                    pageNumber: 1,
                    label: '|<',
                    selected: false,
                    key: key++
                });

                arrayResult.push({
                    pageNumber: currentPage - 1,
                    label: '<',
                    selected: false,
                    key: key++
                });
            }

            let diffMin = 0;
            for (let i = currentPage + 1; i < currentPage + 3; i++) 
            {
                if (i > totalPage) {
                    diffMin++;
                }    
            }

            let diff = 0;
            for (let i = currentPage - 2 - diffMin; i < currentPage; i++) {
                if (i > 0) {
                    arrayResult.push({
                        pageNumber: i,
                        label: i,
                        selected: false,
                        key: key++
                    });
                }    
                else {
                    diff++;
                }
            }

            arrayResult.push({
                pageNumber: currentPage,
                label: currentPage,
                selected: true,
                key: key++
            });

            
            for (let i = currentPage + 1; i < currentPage + 3 + diff; i++) 
            {
                if (i <= totalPage) {
                    arrayResult.push({
                        pageNumber: i,
                        label: i,
                        selected: false,
                        key: key++
                    });
                }    
            }

            if (currentPage < totalPage) {
                arrayResult.push({
                    pageNumber: currentPage + 1,
                    label: '>',
                    selected: false,
                    key: key++
                });
                arrayResult.push({
                    pageNumber: totalPage,
                    label: '>|',
                    selected: false,
                    key: key++
                });
            }

            return arrayResult;
        }
    }
}
</script>

<style scoped>

</style>