<template>
  <div class="input-group input-group-sm">
    <input
        type="textbox"
        :value="formatDate"
        class="form-control form-control-sm"
        ref="datePicker"
        :disabled="disabled"
        :placeholder="placeholder"
    />
    <span class="input-group-text" id="basic-addon2"><i class="bi bi-calendar"></i></span>
  </div>

</template>

<script>
import flatpickr from "flatpickr";
import moment from "moment";

export default {
  name: "BsDatePicker",
  data: function () {
    return {
      hasUpdate: false,
      flatpickrInstance: null
    }
  },
  computed: {
    formatDate() {
      if (this.modelValue === undefined || this.modelValue === null || !(this.modelValue instanceof Date)) return '';
      return moment(this.modelValue).format("yyyy.MM.DD");
    }
  },
  props: {
    modelValue: Date,
    placeholder: {
      type: String,
      default: "Select Date"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    const $this = this;
    this.flatpickrInstance = flatpickr(this.$refs.datePicker, {
      onChange: function (selectedDate) {
        if (selectedDate === null || selectedDate === null || selectedDate.length === 0) {
          $this.$emit('update:modelValue', null);
          return;
        }
        let date = selectedDate[0];
        $this.$emit('update:modelValue', date);
      },
      dateFormat: "Y.m.d"
    });
  }
}
</script>

<style>

</style>