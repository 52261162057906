import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter) 
    {
        const response = await httpService.get(`Reports/TransactionStatusSummaries`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++) 
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },
    
    default() {
        return {
            id: 0,
            waitingForApproval: 0,
            approved: 0,
            rejected: 0,
            inProgress: 0,
            done: 0
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        
        result.id = jsonData.id;
        result.waitingForApproval = jsonData.waitingForApproval;
        result.approved = jsonData.approved;
        result.rejected = jsonData.rejected;
        result.inProgress = jsonData.inProgress;
        result.done = jsonData.done;
        
        return result;
    }
});