import {createRouter, createWebHistory} from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Layout from '@/views/shared/Layout.vue'
// import Login from '@/views/shared/Login.vue'
import SecurityService from '@/services/security-service'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Layout,
        meta: {
            requiresAuth: true,
            title: 'CIPA'
        },
        children: [
            {
                path: '/',
                name: 'Home',
                component: Dashboard
            },
            {
                path: '/signout',
                name: 'SignOut',
                meta: {
                    signout: true
                }
            },
            {
                path: '/mapping-aircraft',
                name: 'MappingAircraft',
                // meta: {
                //   functionId: "MappingAircraft",
                //   accessType: "create"
                // },
                component: () => import('@/views/master-data/mapping-aircraft/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/mapping-aircraft/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/mapping-aircraft/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/mapping-aircraft/Detail.vue')
                    },
                    {
                        path: 'upload-excel',
                        component: () => import('@/views/master-data/mapping-aircraft/UploadExcel.vue')
                    }
                ]
            },
            {
                path: '/master-airlines',
                name: 'MasterAirlines',
                component: () => import('@/views/master-data/master-airlines/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/master-airlines/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/master-airlines/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-airlines/Detail.vue')
                    }
                ]
            },
            {
                path: '/master-customer',
                name: 'MasterCustomer',
                component: () => import('@/views/master-data/master-customer/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/master-customer/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/master-customer/Edit.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-customer/Detail.vue')
                    }
                ]
            },
            {
                path: '/master-vendor',
                name: 'MasterVendor',
                component: () => import('@/views/master-data/master-vendor/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/master-vendor/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/master-vendor/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-vendor/Detail.vue')
                    }
                ]
            },
            {
                path: '/master-unit',
                name: 'MasterUnit',
                component: () => import('@/views/master-data/master-unit/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/master-unit/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/master-unit/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-unit/Detail.vue')
                    }
                ]
            },
            {
                path: '/mapping-price',
                name: 'MappingPrice',
                // meta: {
                //   functionId: "MappingPrice",
                //   accessType: "read"
                // },
                component: () => import('@/views/master-data/mapping-price/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/mapping-price/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/mapping-price/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/mapping-price/Detail.vue')
                    },
                    {
                        path: 'upload-excel',
                        component: () => import('@/views/master-data/mapping-price/UploadExcel.vue')
                    }
                ]
            },
            {
                path: '/master-currencies',
                name: 'MasterCurrencies',
                component: () => import('@/views/master-data/master-currencies/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/master-currencies/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/master-currencies/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-currencies/Detail.vue')
                    }
                ]
            },
            {
                path: '/master-location',
                name: 'MasterLocation',
                component: () => import('@/views/master-data/master-location/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/master-location/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/master-location/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-location/Detail.vue')
                    }
                ]
            },
            {
                path: '/master-time-range',
                name: 'MasterTimeRange',
                component: () => import('@/views/master-data/master-time-range/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/master-time-range/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/master-time-range/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-time-range/Detail.vue')
                    }
                ]
            },
            {
                path: '/base-price-profile',
                name: 'BasePriceProfile',
                component: () => import('@/views/master-data/base-price-profile/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/base-price-profile/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/base-price-profile/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/base-price-profile/Detail.vue')
                    }
                ]
            },
            {
                path: '/fee-profile',
                name: 'FeeProfile',
                component: () => import('@/views/master-data/fee-profile/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/fee-profile/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/fee-profile/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/fee-profile/Detail.vue')
                    }
                ]
            },
            {
                path: '/fee',
                name: 'Fee',
                component: () => import('@/views/master-data/fee/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/fee/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/fee/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/fee/Detail.vue')
                    }
                ]
            },
            {
                path: '/base-price',
                name: 'BasePrice',
                component: () => import('@/views/master-data/base-price/Index.vue'),
                children: [
                    {
                        path: 'generate',
                        component: () => import('@/views/master-data/base-price/Generate.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/base-price/Edit.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/base-price/Detail.vue')
                    }
                ]
            },
            {
                path: '/contracts',
                name: 'Contract',
                component: () => import('@/views/master-data/contracts/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/contracts/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/contracts/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/contracts/Detail.vue')
                    }
                ]
            },
            {
                path: '/spsh',
                name: 'Spsh',
                component: () => import('@/views/master-data/spsh/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/spsh/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/spsh/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/spsh/Detail.vue')
                    }
                ]
            },
            {
                path: '/review-invoice-vendor',
                name: "ReviewInvoiceVendor",
                component: () => import("@/views/transaction/review-invoice-vendor/Index.vue"),
                children: [
                    {
                        path: 'detail/:id',
                        component: () => import("@/views/transaction/review-invoice-vendor/Detail.vue"),
                        children: [
                            {
                                path: 'reject/:id',
                                component: () => import("@/views/transaction/review-invoice-vendor/Reject.vue")
                            }
                        ]
                    }
                ]
            },
            {
                path: '/purchase-order',
                name: "PurchaseOrder",
                component: () => import("@/views/transaction/purchase-order/Index.vue"),
                children: [
                    {
                        path: 'create',
                        component: () => import("@/views/transaction/purchase-order/Create.vue")
                    },
                    {
                        path: 'detail/:id',
                        component: () => import("@/views/transaction/purchase-order/Detail.vue")
                    }
                ]
            },
            {
                path: '/master-kurs',
                name: 'MasterKurs',
                component: () => import('@/views/master-data/master-kurs/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/master-data/master-kurs/Input.vue')
                    },
                    {
                        path: 'edit/:id',
                        component: () => import('@/views/master-data/master-kurs/Input.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-kurs/Detail.vue')
                    }
                ]
            },
            {
                path: '/master-fee',
                name: 'MasterFee',
                component: () => import('@/views/master-data/master-fee/Index.vue'),
                children: [
                    // {
                    //     path: 'create',
                    //     component: () => import('@/views/master-data/master-fee/Input.vue')
                    // },
                    // {
                    //     path: 'edit/:id',
                    //     component: () => import('@/views/master-data/master-fee/Input.vue')
                    // },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/master-data/master-fee/Detail.vue')
                    }
                ]
            },
            {
                path: '/report-price-overseas',
                name: 'ReportPriceOverseas',
                component: () => import('@/views/report/report-price-overseas/Index.vue'),
                children: [
                    {
                        path: 'create',
                        component: () => import('@/views/report/report-price-overseas/Generate.vue')
                    },
                    {
                        path: 'detail/:id',
                        component: () => import('@/views/report/report-price-overseas/Detail.vue')
                    }
                ]
            },
            {
                path: '/progress-payment',
                name: "ProgressPayment",
                component: () => import("@/views/transaction/progress-payment/Index.vue")
            },
            {
                path: '/report-invoice-detail',
                name: "ReportInvoiceDetail",
                component: () => import("@/views/report/invoice-detail/Index.vue")
            },
            {
                path: '/report-pembayaran-vs-penagihan',
                name: "ReportPembayaranVsPenagihan",
                component: () => import("@/views/report/pembayaran-vs-penagihan/Index.vue")
            },
            {
                path: '/report-realisasi-conco-delco',
                name: "ReportRealisasiConcoDelco",
                component: () => import("@/views/report/realisasi-conco-delco/Index.vue")
            },
            {
                path: '/access-denied',
                name: 'AccessDenied',
                component: () => import("@/views/shared/AccessDenied.vue")
            },
            {
                path: '/not-found',
                name: 'NotFound',
                component: () => import("@/views/shared/NotFound.vue")
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


const securityService = new SecurityService();

router.beforeEach(async (to, from, next) => {
    // console.log(to, from, next);
    document.title = 'CIPA';
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const signout = to.matched.some(record => record.meta.signout);

    if (signout) {
        return await securityService.signOut();
    }

    if (!requiresAuth) {
        return next();
    }

    let user = await securityService.getUser();
    if (user == null) {
        return await securityService.signIn();
    }

    const hasFunctionId = to.matched.some(record => record.meta.functionId);
    const hasAccessType = to.matched.some(record => record.meta.accessType);
    if (hasFunctionId && hasAccessType) {
        const functionId = to.meta.functionId;
        const accessType = to.meta.accessType;
        if (await securityService.allowAccess(functionId, accessType)) {
            return next();
        }
        return next('/access-denied');
    }

    return next();
});
export default router
