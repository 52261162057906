<template>
    <input 
        type="textbox" 
        @blur="onBlur($event)"
        @focus="onFocus($event)"
        class="form-control form-control-sm" 
        ref="inputNumber"
        />
</template>

<script>
import accounting from 'accounting';
export default {
    name: "BsCurrency",
    props: ['modelValue'],
    mounted() {
        let value = this.toNumber(this.modelValue);
        value = this.toFormatNumber(value);
        this.$refs.inputNumber.value = value;
    },
    watch: {
        modelValue: function(newVal) {
            let inputValue = this.toNumber(newVal);
            inputValue = this.toFormatNumber(inputValue);
            this.$refs.inputNumber.value = inputValue;
        }
    },
    methods: {
        toFormatNumber(value) {
            if (value === undefined || value === null || value === "" || isNaN(value)) 
            {
                return null;
            }
            return accounting.formatMoney(value, '', '2', ',', '.');
        },
        toNumber(value) {
            if (value === undefined || value === null || value === "" || isNaN(value)) {
                return null;
            }
            return Number(value);
        },
        onBlur(event) {
            let inputValue = event.target.value;
            inputValue = this.toNumber(inputValue);
            let formatNumber = this.toFormatNumber(inputValue);
            this.$emit("update:modelValue", inputValue);
            event.target.value = formatNumber;
        },
        onFocus(event) {
            if (this.modelValue === undefined){
                this.$emit("update:modelValue", null);
            }
            event.target.value = this.modelValue;
        }   
    }
}
</script>

<style>

</style>