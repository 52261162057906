<template>
    <bs-select v-model="text" @onSelect="selectStatus" @onSearch="filterStatus" :options="list">
        <template v-slot:default="props">
            <div>{{props.item.value}}</div>
        </template>
    </bs-select>
</template>
<script>
export default {
    name: 'ReviewInvoiceStatusCombo',
    data: function() {
        return {
            text: '',
            list: null,
            hasFocus: false,
            comboData: [
                {
                    id: '',
                    value: ''
                },
                {
                    id: 'Tertagih',
                    value: 'Tertagih'
                },
                {
                    id: 'Belum Tertagih',
                    value: 'Belum Tertagih'
                },
                {
                    id: 'Belum Terbayar',
                    value: 'Belum Terbayar'
                },
                {
                    id: 'Belum Tertagih-Belum Terbayar',
                    value: 'Belum Tertagih-Belum Terbayar'
                }
            ]
        }
    },
    props: {
        modelValue: Number,
        textDefault: String,
    },
    emits: ['update:modelValue'],
    mounted() {
        this.setComboLabel(this.modelValue);
    },
    methods: {
        async setComboLabel(id) {
            if (id === undefined || id === null || id === '') 
            {
                return;
            }

            let selectedCombo = this.comboData.filter(f => f.id.toLowerCase() == id.toLowerCase());
            if (selectedCombo === undefined || selectedCombo === null || selectedCombo.length === 0) {
                this.text = '';
            }
            else {
                this.text = selectedCombo[0].value;
            }
        },
        selectStatus(item) 
        {
            this.$emit('onBeforeValueChanged', item);
            if (item == null) {
                this.$emit('update:modelValue', null);
            }
            else 
            {
                this.$emit('update:modelValue', item.id);
            }
            
            this.setComboLabel(item == null ? null : item.id);
        },
        async filterStatus(value) {
            let data = this.comboData;
            if (value === undefined || value === null || value === "") {
                this.list = this.comboData;
            }
            else {
                let filtered = data.filter((v) => v.value.toLowerCase().includes(value.toLowerCase()));
                this.list = filtered;
                console.log(this.list);
            }
        }
    }
}
</script>