<template>
    <div>{{modelValue}}</div>
    <!-- <input 
        type="textbox" 
        readonly
        :value="modelValue"
        class="form-control-plaintext form-control-plaintext-sm" /> -->
</template>

<script>
export default {
    name: "BsPlainText",
    props: {
        modelValue: String
    }
}
</script>

<style>

</style>