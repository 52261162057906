import Oidc from 'oidc-client';

var mgr = new Oidc.UserManager({
    userStore: new Oidc.WebStorageStateStore(),  
    authority: process.env.VUE_APP_SSO,
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    // redirect_uri: process.env.VUE_APP_DOMAIN + process.env.VUE_APP_BASE_URL + '/static/callback.html',
    redirect_uri: document.location.origin + process.env.VUE_APP_BASE_URL + '/static/callback.html',
    response_type: 'id_token token',
    scope: 'openid profile api offline_access',

    // post_logout_redirect_uri: process.env.VUE_APP_DOMAIN + process.env.VUE_APP_BASE_URL, // + '/index.html',
    // silent_redirect_uri: process.env.VUE_APP_DOMAIN + process.env.VUE_APP_BASE_URL + '/static/silent-renew.html',
    
    post_logout_redirect_uri: document.location.origin + process.env.VUE_APP_BASE_URL, // + '/index.html',
    silent_redirect_uri: document.location.origin + process.env.VUE_APP_BASE_URL + '/static/silent-renew.html',

    accessTokenExpiringNotificationTime: 10,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
  });

mgr.events.addUserLoaded(function () {    
    console.log('UserLoaded：', arguments);
});
mgr.events.addAccessTokenExpiring(function () {
    console.log('AccessTokenExpiring：', arguments);
});
mgr.events.addAccessTokenExpired(function () {
    console.log('AccessTokenExpired：', arguments);
    // mgr.signinRedirect();
});
mgr.events.addSilentRenewError(function () {
    // mgr.signinRedirect();
    console.error('SilentRenewError：', arguments);
});
mgr.events.addUserSignedOut(function () {
    alert("Processing Logout");
    //console.log('UserSignedOut：', arguments);
    //mgr.removeUser();  
    mgr.signoutRedirect().then(function(resp){      
        console.log("signed out",resp);
    }).catch(function(err){
        console.log(err)
    })
});


export default class SecurityService {
    async getUser() {
        const user = await mgr.getUser();
        return user;
    }

    async getAccessToken() {
        const user = await this.getUser();
        if (user == null) return null;
        return user.access_token;
    }

    async signIn() {
        return await mgr.signinRedirect();
    }

    async signOut() {
        return await mgr.signoutRedirect();
    }

    async getToken() {
        const user = await this.getUser();
        return user.access_token;
    }

    async allowAccess(functionId, accessType) 
    {       
        if (accessType === undefined || accessType === null || accessType === "") return false;
        accessType = accessType.toLowerCase();
        
        let actionTypeEnum = 0;
        if (accessType == "create") {
            actionTypeEnum = 0;
        }
        else if (accessType == "read") {
            actionTypeEnum = 1;
        }
        else if (accessType == "edit") {
            actionTypeEnum = 2;
        }
        else if (accessType == "delete") {
            actionTypeEnum = 3;
        }

        const jsonBody = {
            functionCode: functionId,
            actionType: actionTypeEnum
        };

        const accessToken = await this.getAccessToken();
        let result = await fetch(process.env.VUE_APP_FUNCTION_AUTHORIZATION, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: "POST",
            body: JSON.stringify(jsonBody)
        });

        if (result.status === 200)
        {
            const responseBody = await result.json();
            return responseBody.data;
        }

        return false;
    }

    async allowAccessAdmin(functionId, accessType) {
        if (accessType === undefined || accessType === null || accessType === "") return false;
        accessType = accessType.toLowerCase();
        
        let actionTypeEnum = 0;
        if (accessType == "create") {
            actionTypeEnum = 0;
        }
        else if (accessType == "read") {
            actionTypeEnum = 1;
        }
        else if (accessType == "edit") {
            actionTypeEnum = 2;
        }
        else if (accessType == "delete") {
            actionTypeEnum = 3;
        }
        const jsonBody = {
            functionCode: functionId,
            actionType: actionTypeEnum
        };

        const accessToken = await this.getAccessToken();
        let result = await fetch(process.env.VUE_APP_FUNCTION_AUTHORIZATION, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            method: "POST",
            body: JSON.stringify(jsonBody)
        });

        if (result.status === 200) {
            return true;
        }

        return false;
    }
}