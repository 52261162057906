<template>
    <bs-select v-model="text" @onSelect="selectLocation" @onSearch="filterLocation" :options="list">
        <template v-slot:default="props">
            <div>{{props.item.code}}</div>
        </template>
    </bs-select>
</template>
<script>
import LocationService from '@/services/location-service.js';
export default {
    name: 'LocationCombo',
    data: function() {
        return {
            text: '',
            list: null,
            hasFocus: false
        }
    },
    props: {
        modelValue: Number,
        textDefault: String
    },
    updated() {
        if (!this.hasFocus) 
        {
            this.hasFocus = true;
            this.setComboLabel(this.modelValue);
        }
    },
    emits: ['update:modelValue'],
    methods: {
        async setComboLabel(id) {
            if (id === undefined || id === null || id === '' || id < 1) {
                this.text = '';
                return;
            }

            const obj = await LocationService.getObject(id);
            if (obj.status) {
                this.text = `${obj.data.code}`;
            }
            else 
            {
                alert(`Failed load location with id = '${id}'.`);
            }
        },
        selectLocation(item) {
            if (item == null) {
                this.$emit('update:modelValue', null);
            }
            else 
            {
                this.$emit('update:modelValue', item.id);
            }
            this.setComboLabel(item == null ? null : item.id);
        },
        async filterLocation(value) {
            const filter = {
                codeContains: value
            };
            
            const result = await LocationService.getList(filter);
            let arrayResult = null;
            if (result.status) {
                if (result.data != null && result.data.data != null) 
                {
                    arrayResult = result.data.data;
                }
            }

            this.list = arrayResult;
        }
    }
}
</script>