<template>
    <input 
        type="textbox" 
        :value="modelValue" 
        @input="onChangeModelValue($event)" 
        class="form-control form-control-sm" />
</template>

<script>
export default {
    name: "BsTextbox",
    props: {
        modelValue: [String, Number]
    },
    methods: {
        onChangeModelValue(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>

<style>

</style>